import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"

class PrivacyPolicyPage extends React.Component {


    render() {

        return (
            <LayoutInfo>
                <SEO title="Personvernerklæring"
                description="Personvernerklæringen til Leirskoledagboken AS"
                />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Personvernerklæring</h1>

                        <div>

                            <div><p>Våre kunders tillit er av største viktighet for oss. Leirskoledagboken AS tar derfor ansvar for å beskytte kundens personvern og har oppdatert vår personvernerklæring etter den nye personvernloven GDPR.
                            </p><p>Denne personvernerklæringen forklarer hvordan vi samler inn og benytter informasjon om våre kunder, og hvordan vi beskytter brukernes personvern. </p>
                            </div>
                            <dl>
                                <dt>Kontakt ved spørsmål</dt>
                                <dd>
                                    <p>Har du noen spørsmål om vår personvernerklæring eller håndteringen av personopplysninger må du gjerne kontakte daglig leder som er ansvarlig for oppfølging av
                             personopplysningsloven i Leirskoledagboken AS eller <a href="mailto:personvernansvarlig@leirskoledagboken.no">personvernansvarlig@leirskoledagboken.no</a></p>
                                </dd>
                                <dt>
                                    Personlig data</dt>
                                <dd>
                                    <p>Dette forutsetter at du har gitt skolen samtykke til å sende din personlige data videre.</p>
                                    <p>Personlige data som samles inn fra skoler:</p>
                                    <p>Navn, adresse, telefonnummer, e-postadresse og skole.</p>
                                    <p>Personopplysninger blir ikke utvekslet med andre enn skolen, dvs den samme informasjonen Leirskoledagboken AS mottar fra hver enkelt skole. 
                                        Skolen sender personopplysningene via en kryptert lenke de har fått av Leirskoledagboken AS. Foresatte og elever over 18 år får ikke annen informasjon enn om seg selv. 
                                        All data av denne type lagres kryptert og sikres med begrenset tilgang kun for ansatte i Leirskoledagboken AS som har behov for denne informasjonen.
                                    </p>

                                    <p>
                                    Personopplysningene brukes kun til å informere om fotografering til å informere om bilder fra leirskoleopphold og bestilling av leirskoledagbøker.
                                     Levere fotoprodukter til foresatte og skolen. Foresatte eller fotografert over 18 år har rett til, etter skriftlig anmodning, kostnadsfritt få innsyn 
                                     i dataene, hvor dataene kommer fra, og korrigere eller fjerne gal informasjon. Det er ingen plikt til å oppgi personlig informasjon til Leirskoledagboken AS.
                                    </p>
                                    <p>
                                        Data relatert til fotografering og mottatte personopplysninger som nevnt over lagres og fjernes ikke uten at kunden (skole, foresatte eller elever over 18 år) skriftlig ber
                                        om at opplysningene fjernes/slettes til ansvarlig for oppfølging av personopplysningsloven.
                                    </p>
                                    <p>
                                        Du har til enhver tid mulighet til å vite hva slags opplysninger vi har på deg og eleven du har ansvaret for.
                                    </p>
                                    <p>Dette kan du få ved å kontakte <a href="mailto:personvernansvarlig@leirskoledagboken.no">personvernansvarlig@leirskoledagboken.no</a> eller <a href="mailto:kundeservice@leirskoledagboken.no">kundeservice@leirskoledagboken.no</a> angående dette.</p>
                                </dd>

                                <dt>Slette data</dt>
                                <dd>
                                    <p>Ønsker du at dataene registert om deg i systemet skal slettes, så kontakt <a href="mailto:kundeservice@leirskoledagboken.no">kundeservice@leirskoledagboken.no</a>.</p>
                                </dd>
                                <dt>
                                    Bruk av ”cookies” for å lagre data
                                </dt>
                                <dd>
                                    Ved ditt besøk lagres ”cookies” (informasjonskapsler) som tekstfiler på din datamaskin.
                                </dd>

                            </dl>
                            <dt>Datatilsynet</dt>
                            <dd>Se også Datatilsynet sin <a href="https://www.datatilsynet.no/rettigheter-og-plikter/den-registrertes-rettigheter/" target="blank" >informasjon</a> om personvern rettigheter.</dd>
                        </div>
                    </div>
                </div>
            </LayoutInfo>
        )
    }

}
export default PrivacyPolicyPage
